.input_with_icon {
    position: relative;
  }
  
  .input_with_icon .icon {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
  }
  
  .form_control {
    padding-left: 40px; /* Adjust based on icon size */
    height: 45px;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 15px;
    padding-right: 15px;
  }
  
  textarea.form_control {
    padding-left: 40px;
    height: auto;
  }
  